


















import { Vue, Prop, Component, Model, Emit } from 'vue-property-decorator'
@Component
export default class Input extends Vue {
  @Prop({
    type: Function,
    required: false,
    default: () => false
  })
  onkeyup!: Function

  @Prop({
    type: Number,
    required: false,
    default: 1000000
  })
  max!: number

  @Prop({
    type: String,
    default: 'normal',
    required: false
  })
  styles!: string
  @Prop({
    type: String,
    default: '',
    required: false
  })
  icon!: string
  @Prop({
    type: String,
    default: 'text',
    required: true
  })
  type!: string
  @Prop({
    type: String,
    default: '',
    required: false
  })
  placeholder!: string
  @Prop({
    type: String,
    default: '',
    required: false
  })
  name!: string

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  disabled!: boolean

  @Prop({
    type: String,
    default: '',
    required: false
  })
  status_icon!: string

  @Model('change', {
    type: String
  })
  value!: string

  @Emit('change')
  input(e: any): void {
    return e.target.value
  }
}
