

































import { Vue, Component } from 'vue-property-decorator'
import { Form, Toast } from 'vant'
import ffButton from '@/components/public/Button.vue'
import ffInput from '@/components/public/Input.vue'
import { addGA, getLang } from '../utils'
import { namespace } from 'vuex-class'
const appStore = namespace('app')
@Component({
  components: {
    ffButton,
    ffInput,
    [Form.name]: Form
  }
})
export default class Register extends Vue {
  private email: string = ''

  @appStore.State(state => state.transify) private transify: object
  @appStore.State(state => state.region) private region: any
  @appStore.State(state => state.nationCode) private nationCode: string
  @appStore.State(state => state.registerOpen) private registerOpen: boolean
  @appStore.Action('setProfile') private setProfile!: Function
  @appStore.Action('handleError') private handleError!: Function
  validateEmail = (mail: string) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true
    }
    return false
  }

  onSubmit(): void {
    if (this.email === '') {
      return this.handleError('ADV_REGISTRATION_ERROR_TOAST_EMAIL')
    }
    if (!this.validateEmail(this.email)) {
      return this.handleError('ADV_REGISTRATION_ERROR_TOAST_EMAIL')
    }

    const lang = getLang()
    const profile = {
      lang: this.region,
      email: this.email
    }
    this['$http'].post('/register', profile).then((res: any) => {
      const data = res.data
      Toast['success'](this.transify['ADV_REGISTRATION_SUCCESS'])
      this.setProfile({
        profile: profile,
        apkLink: data.apk_url,
        feedbackQuestions: data.feedback_questions,
        imageUpload: data.feedback_image_upload
      })
      addGA('register', 'success')
      setTimeout(() => {
        this['$router']
          .push({
            path: '/download'
          })
          .catch(() => {
            return
          })
      }, 1000)
    })
  }

  mounted(): void {
    if (!this.registerOpen) {
      this['$router'].push('/').catch(() => {
        return
      })
    }
  }
}
